import { AboutUsComponent } from "./Components/AboutUs";
import { ItemsGallery } from "./Components/Gallery";
import { MenuComponent } from "./Components/Menus/Menu";
import { NavComponent } from "./Components/Nav";
import { restaurant } from "./Data";

export const App = () => {
  return (
    <>
      <NavComponent />
      <div className="relative">
        <div>
          <div className="relative bg-white">
            <div className="rounded-md text-center backdrop-blur-sm p-10 bg-white/30 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
              <h1 className="text-lg font-bold">Välkomen till Sushi Koi.
              </h1>
              <div className="font-bold">
              Vi är stängt på 24, 25 och 26:e december, 1:e januari. På december 31:e öppet mellan kl 11-18
              </div>
            </div>
            <div className="overflow-hidden h-72">
              <img
                alt="Background"
                className="w-full"
                src="https://th.bing.com/th/id/R.5019ce7108793f36390b033f5eafb176?rik=R5FQhsu%2f6S3fJQ&pid=ImgRaw&r=0"
              />
            </div>
          </div>
          <div id="menu" className="border-t-2 border-t-black pt-12">
            <MenuComponent menu={restaurant.menu} />
          </div>
          <div id="gallery" className="mt-10 border-t-2 border-t-black">
            <ItemsGallery images={restaurant.gallery} />
          </div>
          <div id="contact" className="border-t-2 border-t-black pt-12">
            <AboutUsComponent aboutUs={restaurant.aboutUs} />
          </div>
        </div>
      </div>
    </>
  );
};
